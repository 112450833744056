import { unmountComponentAtNode } from 'react-dom';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { initVideoHighlightModal } from './video-highlight-modal/video-highlight-modal';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { hideShowNextArrow } from 'components/carousel-arrows/hide-show-next-arrow';

import { Video } from './types';

interface VideoHightlightsRowCache {
  carouselContainers?: NodeListOf<HTMLElement>;
  slides?: NodeListOf<HTMLElement>;
  rootElement?: HTMLElement;
  [key: string]: Splide | NodeListOf<HTMLElement> | HTMLElement;
}

const cache: VideoHightlightsRowCache = {};

/**
 * Caches re-used elements.
 */
 const setupCache = () => {
  cache.carouselContainers = document.querySelectorAll('.video-highlights-row__splide');
  cache.slides = document.querySelectorAll('.video-highlight');
  cache.rootElement = document.querySelector('#video-playback-modal');
};

/**
 * Sets up each instance of a Video Highlights row.
 */
const setupVideoHighlightsRows = () => {
  if (cache.carouselContainers?.length > 0) {
    cache.carouselContainers.forEach((carouselContainer: HTMLElement, index: number) => {
      cache[`videoHighlightsRow${index}`] = new Splide(carouselContainer, {
        autoHeight: true,
        arrowPath: arrowPath,
        breakpoints: {
          1280: {
            padding: {
              left: '',
              right: '330px'
            },
            perPage: 1,
          },
          1023: {
            padding: {
              left: '',
              right: '200px'
            },
            perPage: 1,
          },
          768: {
            padding: {
              left: '',
              right: '150px'
            },
            perPage: 1,
          },
          600: {
            padding: {
              left: '',
              right: '60px'
            },
            perPage: 1,
          },
          400: {
            padding: {
              left: '',
              right: '30px'
            },
            perPage: 1,
          }
        },
        classes: {
          arrow: 'carousel__arrow',
          prev: 'splide__arrow--prev carousel-prev',
          next: 'splide__arrow--next carousel-next is-hidden',
        },
        padding: {
          left: '',
          right: '80px'
        },
        pagination: false,
        perPage: 2,
        throttle: 300,
      });

      const newCarousel = cache[`videoHighlightsRow${index}`] as Splide;
      hideShowNextArrow(newCarousel, carouselContainer);
      newCarousel.mount();
    });
  }
};

/**
 * Sets up in-page modal for video playback.
 */
const setupVideoHighlightModals = () => {
  cache.slides?.forEach((item: HTMLElement) => {
    const video = JSON.parse(JSON.stringify(item.dataset));
    const modalTriggers = item.querySelectorAll('.video-highlight__modal-trigger');
    if (modalTriggers && modalTriggers.length > 0) {
      modalTriggers.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
          handleOpen(e, video);
        });
      });
    }
  });
};

const handleOpen = (e: Event, video: Video) => {
  document.documentElement.classList.add('has-visible-modal');
  initVideoHighlightModal(video, cache.rootElement, handleClose);
}

const handleClose = () => {
  document.documentElement.classList.remove('has-visible-modal');
  unmountComponentAtNode(cache.rootElement);
}

/**
 * Initializes component.
 */
 const init = (): void => {
  setupCache();
  setupVideoHighlightsRows();
  setupVideoHighlightModals();
};

export { init };
