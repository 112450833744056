
/**
 * Cache for referenced elements.
 * @type {object} _cache
 */
interface CacheExpectations {
  linkShareButton?: HTMLButtonElement,
  linkShareMessage?: HTMLElement
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = (): void => {
  cache.linkShareButton = document.querySelector('.social-share__link--link');
  cache.linkShareMessage = document.querySelector('.social-share__link--link__message');
};


const successMessage = `Link copied to your clipboard.`
const errorMessage = `Something went wrong.`

/**
 * Shows a message after the link is copied
 * @param {string} message the string we want to show
*/
const showMessage = (message:string): void => {
  cache.linkShareMessage.innerHTML = message;
  cache.linkShareMessage.classList.remove('is-hidden');

  setTimeout(function() {
    cache.linkShareMessage.classList.add('is-hidden');
  }, 3000);
};


/**
 * Because Safari is terrible
 * Props to https://josephkhan.me/javascript-copy-clipboard-safari/
 * This works by adding a <textarea> element with the string inside of it
 * then using document.execCommand('copy') to copy it.
 * Yup, it's pretty gross.
 * @param {string} textToCopy the string we want to copy
*/
function copyToClipboardHack(textToCopy: string): void {
  const textArea = <HTMLTextAreaElement>document.createElement('textArea');

  function createTextArea(text:string) {
    textArea.readOnly = true;
    textArea.contentEditable = `true`;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  }

  function copyTo() {
    try {
      document.execCommand('copy');
      showMessage(successMessage);
    } catch (err) {
      showMessage(errorMessage);
    }
    document.body.removeChild(textArea);
  }

  createTextArea(textToCopy);
  selectText();
  copyTo();
}

/**
 * Gets the current url without any lingering query params
 * @returns {string} the url of the current page
*/
const getCurrentUrl = (): string => {
  return location.protocol + '//' + location.host + location.pathname;
}

/**
 * Handler for when a user clicks on the button
 * @param {event} e - click event
*/
const onCopyButtonClick = (e: Event) => {
  e.preventDefault();
  const url = getCurrentUrl();

  // new hotness - works in FF, Chrome, Edge
  // but only in https, so this wont' work locally
  if (navigator.clipboard) {

    navigator.clipboard.writeText(url)
      .then(function () {
        /* success */
        showMessage(successMessage);
      },
      function () {
        /* failure */
        showMessage(errorMessage);
      });
  } else {
    // this is what will work for Safari
    // and local development while we are still at http://dev.pbs.org
    copyToClipboardHack(url);
  }
}

/**
 * Helper function that checks for copy / paste support
 * @returns {boolean}
*/
const copyIsSupported = (): boolean => {
  if (navigator.clipboard || document.queryCommandSupported('copy')) {
    return true;
  }

  return false;
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  cache.linkShareButton.addEventListener('click', onCopyButtonClick);
};

/**
 * Inits embed modal functions.
 */
const init = (): void => {
  if (copyIsSupported()) {
    setupCache();
    if (cache.linkShareButton) {
      cache.linkShareButton.classList.remove('is-hidden');
      addEvents();
    }
  }
};

export { init };
