import React, { useEffect, useRef } from 'react';
import { render } from 'react-dom';

import MyListButton from 'components/my-list/my-list-button/components/button';
import { ReactComponent as CloseIcon } from 'svg/pbs-close.svg';
import { ReactComponent as PlayIcon } from 'svg/pbs-play--no-circle.svg';
import { buildPlayerUrl, toggleVideoPlayback } from 'scripts/modules/pbs-player-iframe';
import { Video, VideoHighlightModalProps } from '../types';

const getPlayerSource = (video: Video) => {
  // using the right variation of the player based on whether we're in the SVP or not
  const playerEmbedType = document
    .querySelector(`body`)
    .classList.contains(`svp`)
    ? `stationplayer/`
    : `portalplayer/`;

  // if there is a window object with an embedURL, use that, else, fallback to player prod
  const playerEmbedURL = window?.PBS?.playerConfig?.embedURL
    || 'https://player.pbs.org/';
  const playerSource = buildPlayerUrl({
    autoplay: true,
    embedType: playerEmbedType,
    embedURL: playerEmbedURL,
    id: video.legacyTpMediaId,
    slug: video.slug,
    disableContinuousPlay: true,
  });
  return playerSource
}

const VideoHighlightModal = (props: VideoHighlightModalProps) => {
  const { video, handleClose } = props;
  const playerSource = getPlayerSource(video);
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);
  const playerId = `player-${video.slug}`;

  const handleOuterClick = (e: React.SyntheticEvent<Element>): void => {
    // if user clicks background outside modal, close the modal
    if ((e.target as Element).classList.contains('video-highlight-modal')) {
      handleClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => {
    // if user presses ESC key, close the modal
    if (e.keyCode === 27 || e.charCode === 27) {
      handleClose();
    }
    // if user presses SPACE, toggle play/pause
    if (e.keyCode === 32 || e.charCode === 32) {
      toggleVideoPlayback(e, 'related');
    }
  };

  useEffect(() => {
    // when mounted, pass focus to the modal
    modalRef.current.focus();
  }, []);

  return (
    <div
      className="video-highlight-modal"
      onClick={handleOuterClick}
      onKeyDown={handleKeyDown}
    >
      <div className="video-highlight-modal__body" ref={modalRef} tabIndex={0}>
        <div className="video-highlight-modal__topbar modal-window__topbar">
          <h2 className="video-highlight-modal__topbar-title">
            {video.title}
          </h2>
        </div>
        <div className="video-highlight-modal__player-container" id={playerId}>
          <iframe
            title={video.title}
            name="related"
            src={playerSource}
            allowFullScreen
            allow="encrypted-media"
          />
        </div>
        <button
          ref={closeButtonRef}
          className="btn--close close-modal"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        <div className="video-highlight-modal__details">
          <div className="video-highlight-modal__body-text">
            <h3 className="video-highlight-modal__body-title">
              {video.title}
            </h3>
            <p className="video-highlight-modal__description">
              {video.description}
            </p>
          </div>
          <div className="video-highlight-modal__buttons">
            <a className="video-highlight-modal__start-watching btn btn--fill--blue"
              href={`/video/${video.relatedSlug}/`}
            >
              <PlayIcon />
              <span className="btn--text">Start Watching</span>
            </a>
            <MyListButton
              cid={video.relatedCid}
              contentType="video"
              customAddText="Add to My List"
              customRemoveText="Added to My List"
              gtmLabel={`${video.ancestor} | ${video.relatedTitle} | ${video.relatedCid} | ${video.relatedType}`}
              slug={video.relatedSlug}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const initVideoHighlightModal = (video: Video, rootElement: HTMLElement, handleClose: (event: MouseEvent) => void): void => {
  render(
    <VideoHighlightModal video={video} key={video.cid} handleClose={handleClose} />,
    rootElement
  );
};

export { initVideoHighlightModal };
