import { init as linkShareButtonInit } from 'scripts/modules/link-share-button';
import { toggleVideoPlayback } from 'scripts/modules/pbs-player-iframe';

/**
 * Adds event handlers.
 */
const addEvents = () => {
  document.addEventListener('keypress', (e) => {
    toggleVideoPlayback(e, 'livestream-player')
  })
};

/**
 * Initializes.
 */
const init = (): void => {
  addEvents();
  linkShareButtonInit();
};

export { init };
