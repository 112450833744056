import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'scripts/modules/external-links';
import 'components/page-header/js/page-header';
import 'components/page-footer/page-footer';
import { init as adsInit } from 'components/ad/ads';
import { init as exploreMoreTopicsInit } from 'components/topic/explore-more/explore-more-topics';
import { init as getUserViewingHistoryInit } from 'scripts/modules/get-user-viewing-history';
import { init as localizationEventsInit } from 'scripts/modules/localization-events';
import { init as myListButtonInit } from 'components/my-list/my-list-button/components/button-group';
import { init as newsLetterInit } from 'components/newsletter/newsletter';
import { init as photoGalleryInit } from 'components/photo-gallery/photo-gallery';
import { init as reactShowRowCarouselInit } from 'components/show-row/react-show-row/react-show-row';
import { init as showPosterVideoRowInit } from 'components/show-poster-video-row/show-poster-video-row';
import { init as showPosterVideoRowSeasonsInit } from 'components/show-poster-video-row/show-poster-video-row-seasons';
import { init as showRowCarouselInit } from 'components/show-row/show-row';
import { init as supportingTextInit } from 'components/supporting-text/supporting-text';
import { init as topicHeroDescriptionInit } from 'components/topic/hero/hero';
import { init as topicHeroVideosInit } from 'components/topic/hero-videos/hero-videos';
import { init as topicLivestreamInit } from 'components/topic/livestream/livestream';
import { init as topTenShowRowCarouselInit } from 'components/show-row/top-ten/top-ten';
import { init as videoHighlightsRowInit } from 'components/video-highlights-row/video-highlights-row';
import { init as videoRowInit } from 'components/video-row/video-row';
import { init as viewingHistoryProgressInit } from 'components/viewing-history-progress/viewing-history-progress';
import { getUserId } from 'scripts/utils/getUserId';

// RWEB-8000 implement another signed-in-user-only show row powered by the Rec Engine
const recEngineShowRowInit = (): void => {
  const userId = getUserId();
  const mountPointId = 'recommendation-engine-show-row';
  // only render the Rec Engine row if user is signed in and the feature flag is set to True
  // (i.e. the mountPoint element exists)
  const mountPoint = document.getElementById(mountPointId);
  if (userId && mountPoint) {
    // initialize the React show row powered by the Recommendation Engine
    const collectionId = 'recommended-dramas';
    const title = 'Recommended Dramas';
    reactShowRowCarouselInit(mountPointId, collectionId, userId, title, null, null);
  }
}

adsInit();
exploreMoreTopicsInit();
getUserViewingHistoryInit();
localizationEventsInit();
myListButtonInit();
newsLetterInit();
photoGalleryInit();
recEngineShowRowInit();
showPosterVideoRowInit();
showPosterVideoRowSeasonsInit();
showRowCarouselInit();
supportingTextInit();
topicHeroDescriptionInit();
topicHeroVideosInit();
topicLivestreamInit();
topTenShowRowCarouselInit();
videoHighlightsRowInit();
videoRowInit();
viewingHistoryProgressInit();
