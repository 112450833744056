import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Splide } from '@splidejs/react-splide';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';

import LoadingIndicator from 'components/shared/loading-indicator';
import SeeAll from 'components/content-strip/components/see-all/see-all';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { SeasonPicker } from 'components/content-strip/components/season-picker/season-picker';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { csRequest, csResponse, Season, Video } from 'components/content-strip/types/content-strip';
import { ReactComponent as TitleCaretRight } from 'svg/pbs-right-caret.svg';
import { createVideoSlides } from 'components/content-strip/utils/utils';
import { getSlides } from 'components/content-strip/varieties/episodes/episodes-data';
import { slugify } from 'scripts/utils/slugify';

interface ShowPosterVideoRowSeasonsProps {
  description?: string;
  initialSelectedSeason: number;
  initialVideos?: Video[];
  posterImage: string;
  seasons?: Season[];
  showLogoColor?: string;
  showLogoWhite?: string;
  showSlug: string;
  title: string;
  titleLink?: string;
}

// A modified version of the calcNumSlidesUp function used for content strips in components/content-strip/utils/utils.tsx
function calcNumSlidesUp(windowWidth?: number): number {
  let numSlidesUp = 3; // arbitrary - safe bet

  if (windowWidth < 480) {
    numSlidesUp = 1;
  } else {
    // otherwise, content strips should follow the rule of 3-up at large, 2-up at small screens
    numSlidesUp = windowWidth >= 768 ? 3 : 2;
  }

  return numSlidesUp;
}

const ShowPosterVideoRowSeasons: React.FC<ShowPosterVideoRowSeasonsProps> = (props) => {
  const { description, initialSelectedSeason, initialVideos, posterImage, seasons, showLogoColor, showLogoWhite, showSlug, title, titleLink } = props;

  const isFirstRender = useRef(true);
  const splideRef = useRef(null);
  const contentParent = 'show';
  const contentType = 'episodes';
  const width: number = useWindowWidth();
  const slideGap = width < 768 ? '8px' : '16px'; // belt and suspenders; React Splide sometimes disregards its own breakpoint settings
  const requestParams: csRequest = useMemo(() => {
    return {
      base: contentParent,
      show_slug: showSlug,
      start: 0,
      limit: 24,
      station_id: Cookies.get('pbsol.station_id') || null,
      season_id: ''
    }
  }, [showSlug]);

  // Set initial state
  const [isFetching, setIsFetching] = useState(false);
  const [slides, setSlides] = useState(createVideoSlides(initialVideos));
  const [currentIndex, setCurrentIndex] = useState(0); // start at index 0
  const [numSlidesUp, setNumSlidesUp] = useState(calcNumSlidesUp(width));
  const [itemsRemaining, setItemsRemaining] = useState(slides.length - currentIndex - numSlidesUp); // items remaining to be scrolled (to the right)
  const [selectedSeason, setSelectedSeason] = useState(initialSelectedSeason);
  const [shouldDisplaySeeAllButton, setShouldDisplaySeeAllButton] = useState(false);

  // When a change to the browser width is detected, update the number of slides up accordingly
  useEffect(() => {
    setNumSlidesUp(calcNumSlidesUp(width));
  }, [width]);

  // When the selected season changes, fetch the new videos and set new state values
  useEffect(() => {
    // first time rendering the component, we want to skip all of this
    if (isFirstRender.current || seasons.length < 1) {
      isFirstRender.current = false;
      return;
    } else {
      const seasonObject = seasons.find(season => season.ordinal === selectedSeason);
      setIsFetching(true);
      getSlides(seasonObject, requestParams).then((response: csResponse) => {
        const responseVideos = response.content;
        const slides = createVideoSlides(responseVideos);
        setSlides(slides);
        setItemsRemaining(slides.length);
        setCurrentIndex(0);
        setIsFetching(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason]);

  // When the carousel moves, currentIndex is updated;
  // the number of items remaining should be updated accordingly.
  useEffect(() => {
    setItemsRemaining(slides.length - currentIndex - numSlidesUp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  // We adjust the display of the carousel arrows and See All button
  // depending on the number of items remaining in the carousel.
  useEffect(() => {

    // When number of slides remaining in the carousel is low enough,
    // decide if we should display the See All button.
    if (itemsRemaining < numSlidesUp && width > 600) {
      setShouldDisplaySeeAllButton(true);
    } else {
      setShouldDisplaySeeAllButton(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRemaining]);

  return (
    <div className="show-poster-video-row--with-seasons">
      <div className="show-poster-video-row__header">
        { (showLogoColor || showLogoWhite) && (
          <div className="show-poster-video-row__logo-container">
            <a className="show-poster-video-row__logo-link" href={`/show/${showSlug}/`}>
              {showLogoColor && (
                <img className="show-poster-video-row__logo-image show-poster-video-row__logo-image--color" src={`${showLogoColor}?format=auto&crop=200x50`} alt={title} loading="lazy" />
              )}
              {showLogoWhite && (
                <img className="show-poster-video-row__logo-image show-poster-video-row__logo-image--white" src={`${showLogoWhite}?format=auto&crop=200x50`} alt={title} loading="lazy" />
              )}
            </a>
          </div>
        )}
        <div className="show-poster-video-row__title-seasons-wrapper">
          <h2 className="carousel__title content-section-title" id={slugify(title)}>
            {titleLink ? (
              <a
                href={titleLink}
                className="content-section-title__link"
              >
                <span>{title}</span>
                <TitleCaretRight />
              </a>
            ) : (
              <span>{title}</span>
            )}
          </h2>
          {description && (
            <p className="show-poster-video-row__description">
              {description}
            </p>
          )}
          {seasons && seasons.length > 1 && (
            <SeasonPicker
              uniqueId={showSlug}
              contentType={contentType}
              context={contentParent}
              selectedSeason={selectedSeason}
              setSelectedSeason={setSelectedSeason}
              seasonsList={seasons}
            />
          )}
        </div>
      </div>

      <div className="show-poster-video-row__content">
        <div className="show-poster-video-row__poster-container">
          <a className="show-poster-video-row__poster-link" href={`/show/${showSlug}/`}>
            <img
              className="show-poster-video-row__poster-image img-responsive"
              src={`${posterImage}?format=auto&crop=300x450`}
              alt={title}
              width="224"
              height="335"
              loading="lazy"
            />
          </a>
        </div>
        {isFetching ? (
          <LoadingIndicator />
        ) : (
          <div className="show-poster-video-row__video-container">
            <Splide
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onMoved={(splideInstance: any, newIndex: number, oldIndex: number) => {
                setCurrentIndex(newIndex);
                if (newIndex < oldIndex) {
                  // if we're moving left, don't display the See All button
                  setShouldDisplaySeeAllButton(false);
                }
              }}
              options={{
                arrowPath: arrowPath, // using our custom caret SVG in splide's built-in arrows
                breakpoints: {
                  767: {
                    gap: '8px',
                    perPage: 2
                  },
                  479: { perPage: 1 }
                },
                classes: {
                  arrow: 'carousel__arrow',
                  prev: 'splide__arrow--prev carousel-prev',
                  next: `splide__arrow--next carousel-next`,
                },
                drag: true,
                gap: slideGap,
                padding: {
                  left: '0px',
                  right: '64px',
                },
                pagination: false,
                perPage: numSlidesUp,
                rewind: false,
                slideFocus: false,
              }}
              ref={splideRef}
            >
              {slides}
            </Splide>
            {shouldDisplaySeeAllButton && slides.length > numSlidesUp && (
              <SeeAll
                contentType={contentType}
                seeAllUrl={`/show/${showSlug}/episodes/season/${selectedSeason}/`}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const init = (): void => {
  // Check that the root DOM element(s) exist before trying to mount the component(s).
  const mountPoints = document.querySelectorAll(`[id^="spvr-seasons-"]`);

  if (mountPoints?.length > 0) {
    mountPoints.forEach((mountPoint: HTMLElement) => {
      // for each mount point, find the corresponding JSON script tag
      const showSlug = mountPoint.getAttribute('data-show-slug');
      const jsonNode = document.getElementById(`spvr-data-${showSlug}`);

      // only try to mount the component if this data exists
      if (jsonNode) {
        const spvrData = JSON.parse(jsonNode.textContent);
        const props = {
          description: spvrData['description'],
          initialSelectedSeason: spvrData['default_season'],
          initialVideos: spvrData['content'],
          posterImage: spvrData['poster_image'],
          seasons: spvrData['seasons'],
          showLogoColor: spvrData['show_logo_color'],
          showLogoWhite: spvrData['show_logo_white'],
          showSlug: showSlug,
          title: spvrData['title'],
          titleLink: spvrData['title_link'],
        }
        const root = createRoot(mountPoint!);
        root.render(<ShowPosterVideoRowSeasons {...props} />);
      }
    });
  }
};

export { init };
