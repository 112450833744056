import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { init as videoSummariesInit } from 'components/video/summary/video';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { hideShowNextArrow } from 'components/carousel-arrows/hide-show-next-arrow';

interface ShowPosterVideoRowCache {
  showPosterVideoRows?: NodeListOf<HTMLDivElement>;
  [key: string]: Splide | NodeListOf<HTMLDivElement>;
}

const cache: ShowPosterVideoRowCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.showPosterVideoRows = document.querySelectorAll(
    '.show-poster-video-row__splide'
  );
};

/**
 * Sets up each Show Poster Video Row instance.
 */
const setupShowPosterVideoRows = () => {
  if (cache.showPosterVideoRows?.length > 0) {
    cache.showPosterVideoRows.forEach((carousel: HTMLElement, index: number) => {
      // init the video summaries (apply tooltips and popovers)
      videoSummariesInit(carousel);
      // first we make an id based on the index,
      // then we create a carousel and add it to the cache.
      // TS note: we have to cast the root element (a node) to the HTMLElement type
      // in order for the tsc compiler to accept it.
      const typedRoot = <HTMLElement>cache.showPosterVideoRows[index];
      cache[`carousel${index}`] = new Splide(typedRoot, {
        arrowPath: arrowPath,
        breakpoints: {
          1280: {
            padding: {
              left: '0px',
              right: '64px',
            },
            perPage: 3,
          },
          767: {
            gap: '8px',
            padding: {
              left: '64px',
              right: '64px',
            },
            perPage: 2,
          },
          480: {
            gap: '8px',
            padding: {
              left: '64px',
              right: '64px',
            },
            perPage: 1,
          },
        },
        classes: {
          arrow: 'carousel__arrow',
          prev: 'splide__arrow--prev carousel-prev',
          next: `splide__arrow--next carousel-next is-hidden`,
        },
        gap: '16px',
        padding: {
          left: '0px',
          right: '64px',
        },
        pagination: false,
        perPage: 3,
        throttle: 300,
      })

      const newCarousel = cache[`carousel${index}`] as Splide;
      hideShowNextArrow(newCarousel, typedRoot);
      newCarousel.mount();
    });
  }
};

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  setupShowPosterVideoRows();
};

export { init };
