import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { init as videoSummariesInit } from 'components/video/summary/video';
import { arrowPath } from 'components/carousel-arrows/arrow-path';

interface TopicHeroVideosCache {
  topicHeroVideos?: HTMLElement;
  [key: string]: Splide | HTMLElement;
}

const cache: TopicHeroVideosCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.topicHeroVideos = document.querySelector(
    '.topic-hero-videos__splide'
  );
};

/**
 * Sets up each Topic Hero Videos instance.
 */
const setupTopicHeroVideos = () => {
  if (cache.topicHeroVideos) {
    // init the video summaries (apply tooltips and popovers)
    videoSummariesInit(cache.topicHeroVideos);
    cache[`carousel`] = new Splide(cache.topicHeroVideos, {
      arrowPath: arrowPath,
      // remember options values are for *below* these numbers
      breakpoints: {
        1024: {
          gap: '16px',
          perPage: 3,
        },
        767: {
          gap: '8px',
          perPage: 2,
        },
        480: {
          perPage: 1,
        },
      },
      classes: {
        arrow: 'carousel__arrow',
        prev: 'splide__arrow--prev carousel-prev',
        next: `splide__arrow--next carousel-next`,
      },
      drag: true,
      // 1024 and *above*
      gap: '24px',
      pagination: false,
      perPage: 3,
    }).mount();
  }
};

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  setupTopicHeroVideos();
};

export { init };
