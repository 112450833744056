import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

type topicHeroElementCache = {
  descriptionShort?: HTMLElement,
  descriptionButton?: HTMLElement,
  descriptionLong?: HTMLElement,
  popoverTrigger?: HTMLElement,
  popoverContent?: HTMLElement,
  popoverClose?: HTMLElement,
}

const cache: topicHeroElementCache = {};
const setupCache = () => {
  // description toggling elements (below $sm only)
  cache.descriptionShort = document.querySelector('.topic-hero__description--short');
  cache.descriptionButton = document.querySelector('.topic-hero__description__button');
  cache.descriptionLong = document.querySelector('.topic-hero__description--long');
  // description popover elements (above $sm only)
  cache.popoverTrigger = document.querySelector('.topic-hero__popover-trigger');
  cache.popoverContent = document.querySelector('.topic-hero__popover');
  cache.popoverClose = document.querySelector('.topic-hero__popover-close');
};

// replace short description and "MORE" button with long description
const onDescriptionClick = () => {
  cache.descriptionShort.classList.add('is-hidden');
  cache.descriptionButton.classList.add('is-hidden');
  cache.descriptionLong.classList.remove('is-hidden');
}

const addEventListeners = () => {
  if (cache.descriptionButton) {
    cache.descriptionButton.addEventListener('click', onDescriptionClick);
  }
};

const setupDescriptionPopover = () => {
  if (cache.popoverTrigger && cache.popoverContent) {
    tippy(cache.popoverTrigger, {
      allowHTML: true,
      content: cache.popoverContent,
      delay: [100, 100],
      interactive: true,
      maxWidth: 'none',
      onCreate(instance) {
        const closeButton: HTMLElement = cache.popoverContent.querySelector('.topic-hero__popover-close');
        closeButton.addEventListener('click', () => instance.hide());
      },
      placement: 'right',
      theme: 'light',
      touch: false,
      trigger: 'click'
    });
  }
};

const init = (): void => {
  setupCache();
  addEventListeners();
  setupDescriptionPopover();
};

export { init };
